<template src="./app.html"></template>

<script>
    import dmAppMixin from "../dmFramework/dmJS/dmAppMixin";
    import signInPage from './components/signInPage/signInPage'
    import usersPage from './components/usersPage/usersPage'
    import usersEditModal from './components/usersEditModal/usersEditModal'
    import passwordEditModal from './components/passwordEditModal/passwordEditModal'
    import farmersPage from './components/farmersPage/farmersPage'
    import fieldsPage from './components/fieldsPage/fieldsPage'
    import fieldTagsEditModal from './components/fieldTagsEditModal/fieldTagsEditModal'
    import tagsPage from './components/tagsPage/tagsPage';
    import tagsEditModal from './components/tagsEditModal/tagsEditModal';
    import fieldsUploadModal from './components/fieldsUploadModal/fieldsUploadModal'
    import fieldsUploadScoresheetModal from './components/fieldsUploadScoresheetModal/fieldsUploadScoresheetModal'
    import fieldsAssignmentEditModal from './components/fieldsAssignmentEditModal/fieldsAssignmentEditModal'
    import resourcesPage from './components/resourcesPage/resourcesPage'
    import reportsPage from './components/reportsPage/reportsPage'
    import verificationsPage from './components/verificationsPage/verificationsPage'
    import verificationsEditModal from './components/verificationsEditModal/verificationsEditModal'
    import outcomesPage from './components/outcomesPage/outcomesPage'
    import outcomesExportModal from './components/outcomesExportModal/outcomesExportModal'
    import supportModal from './components/supportModal/supportModal'
    import dashboardPage from './components/dashboardPage/dashboardPage'
    import faqsPage from './components/faqsPage/faqsPage'

    export default {
        name: 'app',
        mixins: [
            dmAppMixin
        ],
        components: {
            signInPage,
            usersPage,
            usersEditModal,
            passwordEditModal,
            farmersPage,
            fieldsPage,
            fieldTagsEditModal,
            tagsPage,
            tagsEditModal,
            fieldsUploadModal,
            fieldsUploadScoresheetModal,
            fieldsAssignmentEditModal,
            resourcesPage,
            reportsPage,
            verificationsPage,
            verificationsEditModal,
            faqsPage,
            supportModal,
            dashboardPage
        },
        data: function () {
            return {
                mnuSidebarItems: [],
                mnuSidebarItemsAdmin: [
                    {
                        text: 'Dashboard',
                        onClick: this.mnuDashboardOnClick,
                        iconCss: 'fas fa-tachometer-alt'
                    },
                    {
                        text: 'Users',
                        onClick: this.mnuAdminUsersOnClick,
                        iconCss: 'fa fa-users'
                    },
                    {
                        text: 'Farmers',
                        onClick: this.mnuAdminFarmersOnClick,
                        iconCss: 'fa fa-leaf'
                    },
                    {
                        text: 'Fields',
                        onClick: this.mnuAdminFieldsOnClick,
                        iconCss: 'fas fa-tractor'
                    },
                    {
                        text: 'Program/Project Tags',
                        onClick: this.mnuAdminTagsOnClick,
                        iconCss: 'fa fa-tag',
                    },
                    {
                        text: 'Reports',
                        onClick: this.mnuAdminReportsOnClick,
                        iconCss: 'fa fa-bar-chart'
                    },
                    {
                        text: 'Verifications',
                        onClick: this.mnuVerificationsOnClick,
                        iconCss: 'fa fa-key',
                    },
                    {
                        text: 'Resources',
                        onClick: this.mnuAdminResourcesOnClick,
                        iconCss: 'fa fa-globe'
                    },
                    {
                        text: 'FAQs',
                        onClick: this.mnuAdminFaqsOnClick,
                        iconCss: 'fa fa-question'
                    },
                ],
                mnuSidebarItemsLicensee: [
                    {
                        text: 'Dashboard',
                        onClick: this.mnuDashboardOnClick,
                        iconCss: 'fas fa-tachometer-alt'
                    },
                    {
                        text: 'Users',
                        onClick: this.mnuAdminUsersOnClick,
                        iconCss: 'fa fa-users'
                    },
                    {
                        text: 'Farmers',
                        onClick: this.mnuAdminFarmersOnClick,
                        iconCss: 'fa fa-leaf'
                    },
                    {
                        text: 'Fields',
                        onClick: this.mnuAdminFieldsOnClick,
                        iconCss: 'fas fa-tractor'
                    },
                    {
                        text: 'Reports',
                        onClick: this.mnuAdminReportsOnClick,
                        iconCss: 'fa fa-bar-chart'
                    },
                    {
                        text: 'Verifications',
                        onClick: this.mnuVerificationsOnClick,
                        iconCss: 'fa fa-key',
                    },
                    {
                        text: 'Outcomes',
                        onClick: this.mnuOutcomesOnClick,
                        iconCss: 'fa fa-list-ol',
                    },
                    {
                        text: 'Resources',
                        onClick: this.mnuAdminResourcesOnClick,
                        iconCss: 'fa fa-globe'
                    },
                ],
                mnuSidebarItemsVerifier: [
                    {
                        text: 'Verifications',
                        onClick: this.mnuVerificationsOnClick,
                        iconCss: 'fa fa-key',
                    },
                    {
                        text: 'Resources',
                        onClick: this.mnuAdminResourcesOnClick,
                        iconCss: 'fa fa-globe'
                    },
                    {
                        text: 'FAQs',
                        onClick: this.mnuAdminFaqsOnClick,
                        iconCss: 'fa fa-question'
                    },
                ],
                mnuRightItems: [
                    {
                        text: 'User',
                        iconCss: 'fa fa-user',
                        items: [
                            {
                                text: 'My Profile',
                                onClick: this.mnuMyProfileOnClick,
                                iconCss: 'fa fa-user'
                            },
                            {
                                text: 'Sign Out',
                                onClick: this.mnuSignOutOnClick,
                                iconCss: 'fa fa-sign-out'
                            },
                        ]
                    }
                ],
                mnuSupportItems: [
                    {
                        text: 'Support',
                        onClick: this.mnuSupportOnClick,
                        iconCss: 'fa fa-question-circle'
                    },
                ],
                tmpMainTemplateName: '',
                signedInUser: null,
                moduleName: '',
                sidebarCollapsed: false,
            }
        },
        methods: {
            toggleClick: function () {
                this.$refs.sidebarInstance.toggle();
            },
            logoOnClick() {

            },
            mnuOnClick(args) {
                if (args.item.onClick) {
                    args.item.onClick();
                }
            },
            mnuDashboardOnClick() {
                this.loadPage('dashboardPage');
            },
            mnuAdminUsersOnClick() {
                this.loadPage('usersPage');
            },
            mnuAdminFieldsOnClick() {
                this.loadPage('fieldsPage');
            },
            mnuAdminTagsOnClick() {
                this.loadPage('tagsPage');
            },
            mnuAdminVerificationsOnClick() {

            },
            mnuAdminResourcesOnClick() {
                this.loadPage('resourcesPage');
            },
            mnuAdminReportsOnClick() {
                this.loadPage('reportsPage');
            },
            mnuOutcomesOnClick() {
                this.loadPage('outcomesPage');
            },
            mnuAdminFaqsOnClick() {
                this.loadPage('faqsPage');
            },
            mnuVerificationsOnClick() {
                this.loadPage('verificationsPage');
            },
            mnuAdminFarmersOnClick() {
                this.loadPage('farmersPage');
            },
            mnuSignOutOnClick() {
                this.doSignOut();
            },
            mnuSupportOnClick() {
                eventBus.$emit('showAppModal', this, 'supportModal', {});
            },
            mnuMyProfileOnClick() {
                this.countyOptions = [];
                DM.http({
                    method: "GET",
                    url: "/Counties/GetAll",
                }).then(response => {
                    response.forEach(type => {
                        let dataObject = {text: type.CountyName, value: type.CountyID};
                        this.countyOptions.push(dataObject);
                    });

                    return DM.http({
                        method: "GET",
                        url: "/Users/GetByID",
                        params: {
                            UserID: app.signedInUser.UserID
                        }
                    });
                }).then(response => {
                    app.signedInUser = response;
                    this.signedInUser = response;

                    eventBus.$emit('showAppModal', this, 'usersEditModal', {
                        mode: 'profile',
                        user: response,
                        countyOptions: this.countyOptions,
                        callback: (user) => {
                            eventBus.$emit('setSignedInUser', user);
                        }
                    });
                });
            },
            doSignOut() {
                DM.http({
                    method: "POST",
                    url: "/Users/SignOut",
                })
                    .then(response => {
                        DM.localStorageRemove(app.localStorageAuthTokenName);
                        app.signedInUser = null;
                        this.signedInUser = null;
                        this.loadPage('signInPage');
                    });
            },
            getUserByID(userID) {
                DM.http({
                    method: "GET",
                    url: "/Users/GetByID",
                    params: {
                        UserID: userID
                    }
                })
                    .then(response => {
                        app.signedInUser = response;
                        this.signedInUser = response;
                        this.mnuRightItems[0].text = response.FirstName + ' ' + response.LastName;
                        setTimeout(() => {
                            if(response.UserType === 'Farmer') {
                                DM.alertShow('You do not have access to this application. Contact <a href="info@starfreetool.com">info@starfreetool.com</a> to request access.');
                                DM.localStorageRemove(app.localStorageAuthTokenName);
                            }

                            if (response.UserType === 'Admin') {
                                this.mnuSidebarItems = this.mnuSidebarItemsAdmin;
                                this.loadPage('dashboardPage');
                            }

                            if (response.UserType === 'Licensee') {
                                this.mnuSidebarItems = this.mnuSidebarItemsLicensee;
                                this.loadPage('dashboardPage');
                            }

                            if(response.UserType === 'Verifier') {
                                this.mnuSidebarItems = this.mnuSidebarItemsVerifier;
                                this.loadPage('verificationsPage');
                            }
                        }, 300);
                    });
            },
            signInWithAuthToken() {
                return DM.http({
                    method: "POST",
                    url: "/Users/SignInWithAuthToken",
                })
                    .then(response => {
                        this.getUserByID(response.UserID);
                    })
                    .catch(() => {
                        DM.localStorageRemove(app.localStorageAuthTokenName);
                    });
            },
            initializeMap() {
                window.mapScript = document.createElement('script');
                window.mapScript.src = 'https://maps.google.com/maps/api/js?v=weekly&key=AIzaSyCfx1qGVAX91cZxQuh-2q_h_B7cCGi3HiI';
                document.body.appendChild(window.mapScript);
                window.mapScript.addEventListener('load', () => {
                  this.loadFieldLabelOverlay();
                });
            },
            loadFieldLabelOverlay() {
              window.fieldLabelOverlayScript = document.createElement('script');
              window.fieldLabelOverlayScript.src = './FieldLabelOverlay.js';
              document.body.appendChild(window.fieldLabelOverlayScript);
              window.fieldLabelOverlayScript.addEventListener('load', (overlay) => {
                this.$store.dispatch('setGoogleMapsIsLoaded', true);
              });
            },
        },
        created() {
            this.initializeMap();
        },
        mounted() {
            eventBus.$on('setSignedInUserAndLoadStartPage', (userData) => {
                this.getUserByID(userData.UserID);
            });

            eventBus.$on('setSignedInUser', (userData) => {
                app.signedInUser = userData;
                this.signedInUser = userData;
                this.$set(this.mnuRightItems, 0, {
                    text: userData.FirstName + ' ' + userData.LastName,
                    iconCss: 'fa fa-user',
                    items: [
                        {
                            text: 'My Profile',
                            onClick: this.mnuMyProfileOnClick,
                            iconCss: 'fa fa-user'
                        },
                        {
                            text: 'Sign Out',
                            onClick: this.mnuSignOutOnClick,
                            iconCss: 'fa fa-sign-out'
                        },
                    ]
                });
            });

            eventBus.$on('resetUserToSignIn', (userData) => {
                // This is called when a 401 is returned
                this.loadPage('signInPage');
            });

            let authToken = DM.localStorageGet(app.localStorageAuthTokenName);
            if (authToken) {
                DM.authToken = authToken;
                this.signInWithAuthToken();
            } else {
                this.loadPage('signInPage');
            }
        }
    }
</script>

<style>
    @import "../node_modules/@syncfusion/ej2-icons/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-base/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-vue-buttons/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-vue-popups/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";
    @import "../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
    @import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
    @import '../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css';
</style>
